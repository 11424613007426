import React from "react"
import "./share-buttons.css"
import { Link } from "gatsby"

import { Facebook, Linkedin, Instagram } from "react-feather"
import { FacebookShareButton, LinkedinShareButton } from "react-share"
const ShareButtons = ({ url, title, description, instaLink }) => (
  <div className="post-meta-share-icons">
    <FacebookShareButton url={url} quote={description}>
      <Facebook size={24} />
    </FacebookShareButton>
    <Link to={instaLink}>
      <Instagram />
    </Link>
    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin />
    </LinkedinShareButton>
  </div>
)
export default ShareButtons
