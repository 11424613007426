import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import moment from "moment"

import ShareButtons from "../components/share-buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogDetails = ({ data, location }) => {
  const blog = data.blogs
  const instaLink = data.sociallink.instagram
  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Layout>
      <Seo title={blog.title} location={location} />
      <div className="cha-blog-details__container">
        {blog.image && (
          <img
            className="cha-blog-details__container__header"
            src={blog.image.url}
            alt={"Blog Details"}
          />
        )}

        <div className="cha-blog-details__container__body containers">
          <h1 className="cha-blog-details__container__body__title h1-header">
            {blog.title}
          </h1>
          {blog.details && (
            <span className="cha-blog-details__container__body__text1">
              {blog.details}
            </span>
          )}

          <div className="cha-blog-details__container__body__author-container">
            <div className="cha-blog-details__container__body__author-container__details__container">
              <StaticImage
                className="cha-blog-details__container__body__author-container__avatar"
                src="../images/blog-d-header.jpg"
                alt={"Blog Details"}
              />
              <div className="cha-blog-details__container__body__author-container__details__container__text__container">
                <div className="cha-blog-details__container__body__author-container__details__container__text__container__text1">
                  {blog.author_TextField}
                </div>
                <div className="cha-blog-details__container__body__author-container__details__container__text__container__text2">
                  Published on {moment(blog.publishingDate).format("ll")}
                </div>
              </div>

              <div className="cha-blog-details__container__body__author-container__share">
                <div className="cha-blog-details__container__body__author-container__share__text">
                  Share:
                </div>
                <ShareButtons
                  url={url}
                  title={blog.title}
                  description={blog.introCopy}
                  instaLink={instaLink}
                />
              </div>
            </div>
            {blog.introCopy && (
              <div className="cha-blog-details__container__bodyText">
                {parse(blog.introCopy)}
              </div>
            )}

            {blog.body && (
              <div className="cha-blog-details__container__bodyText">
                {parse(blog.body)}
              </div>
            )}

            {blog.middleImage && (
              <img
                className="cha-blog-details__container__bodyImage"
                src={blog.middleImage.url}
                alt={"Blog Details"}
              />
            )}

            {blog.middleBody && (
              <div className="cha-blog-details__container__bodyText">
                {parse(blog.middleBody)}
              </div>
            )}

            {blog.callout && (
              <div className="cha-blog-details__container__callout">
                {parse(blog.callout)}
              </div>
            )}

            {blog.secondaryTitle && <h2>{blog.secondaryTitle}</h2>}

            {blog.thirdBody && (
              <div className="cha-blog-details__container__bodyText">
                {parse(blog.thirdBody)}
              </div>
            )}

            {blog.bottomImage && (
              <img
                className="cha-blog-details__container__bodyImage"
                src={blog.bottomImage.url}
                alt={"Blog Details"}
              />
            )}

            {blog.closing && (
              <div className="cha-blog-details__container__bodyClosing">
                {parse(blog.closing)}
              </div>
            )}
          </div>

          {/* <div className="cha-blog-details__container__body__text2">
          Quisque at odio semper, elementum leo sed, congue tellus. Proin nunc
          mauris, porttitor ut eleifend ut, consectetur ut dolor. In hac
          habitasse platea dictumst. Pellentesque ornare nulla ut quam blandit
          scelerisque. Suspendisse non orci id elit tempor rhoncus ac id nunc.
          Integer scelerisque at turpis sit amet faucibus. Etiam non euismod
          urna. Suspendisse vel ex justo. Vivamus posuere porttitor ante eu
          hendrerit.
        </div>

        <StaticImage
          className="cha-blog-details__container__body__img1"
          src="../images/blog-body1.jpg"
          alt={"Blog Details"}
        />

        <div className="cha-blog-details__container__body__text2">
          In lacinia sapien a libero accumsan facilisis. Donec vitae lorem
          massa. Aliquam tristique vehicula enim ut luctus. Vivamus gravida
          dignissim ligula, dictum laoreet elit malesuada ac. Praesent est
          justo, posuere a nisl porta, pharetra posuere lectus. Nulla velit
          odio, tincidunt vel metus a, viverra placerat ligula. Donec id nisl et
          risus volutpat tempor a eget mauris. Nullam velit eros, porttitor et
          urna sit amet, ullamcorper vestibulum magna. Quisque consequat arcu
          eros, lobortis faucibus purus facilisis vitae. Nulla at nunc non purus
          vehicula elementum.
        </div>

        <div className="cha-blog-details__container__body__quote__container">
          <StaticImage
            className="cha-blog-details__container__body__quote__container__icon cha-blog-details__container__body__quote__container__icon__left"
            src="../images/quote-icon.png"
            alt={"Blog Details"}
          />
          <div className="cha-blog-details__container__body__quote__container__text1">
            Suspendisse sagittis id lacus eget vulputate. Ut nec arcu ut sem
            molestie tincidunt luctus eget tellus.
          </div>
          <div className="cha-blog-details__container__body__quote__container__text2">
            Parsley Montana
          </div>
          <StaticImage
            className="cha-blog-details__container__body__quote__container__icon cha-blog-details__container__body__quote__container__icon__right"
            src="../images/quote-icon.png"
            alt={"Blog Details"}
          />
        </div>

        <div className="cha-blog-details__container__body__text3">
          Big heading for a new topic
        </div>

        <div className="cha-blog-details__container__body__text4">
          Morbi pellentesque finibus libero, in blandit lorem eleifend eget.
          Praesent egestas hendrerit augue a vestibulum. Nullam fringilla, eros
          malesuada eleifend placerat, lacus tellus egestas erat, nec varius sem
          lorem ut mauris. Morbi libero felis.
        </div>

        <ul>
          <li className="cha-blog-details__container__body__text4">
            Morbi pellentesque finibus libero, in blandit lorem eleifend eget.
            Praesent egestas hendrerit augue a vestibulum. Nullam fringilla,
            eros malesuada eleifend placerat, lacus tellus egestas erat, nec
            varius sem lorem ut mauris. Morbi libero felis.
          </li>

          <li className="cha-blog-details__container__body__text4">
            Cras eget dolor accumsan, blandit risus vitae, faucibus erat.
            Aliquam scelerisque, diam ut feugiat scelerisque, diam felis
            venenatis purus, eget cursus enim turpis at sem. Fusce nec tristique
            dolor, sit amet tristique purus.
          </li>
        </ul>

        <StaticImage
          className="cha-blog-details__container__body__img1"
          src="../images/blog-body2.jpg"
          alt={"Blog Details"}
        />

        <div className="cha-blog-details__container__body__text4">
          Quisque at odio semper, elementum leo sed, congue tellus. Proin nunc
          mauris, porttitor ut eleifend ut, consectetur ut dolor. In hac
          habitasse platea dictumst. Pellentesque ornare nulla ut quam blandit
          scelerisque. Suspendisse non orci id elit tempor rhoncus ac id nunc.
          Integer scelerisque at turpis sit amet faucibus. Etiam non euismod
          urna.
        </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetails

export const query = graphql`
  query($id: String!) {
    sociallink {
      instagram
    }
    blogs(id: { eq: $id }) {
      title
      introCopy
      author_TextField
      publishingDate
      details
      body
      middleImage {
        url
      }
      middleBody
      callout
      secondaryTitle
      thirdBody
      bottomImage {
        url
      }
      image {
        url
      }
      closing
      tags_TextField
    }
  }
`
